.blog_post {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.post_featured {
  width: 100%;
}

.post_featured_image {
  width: 100%;
  z-index: 10;
}

.post_featured_content {
  text-align: center;
}

.post_featured_title {
  padding: 0;
  margin: 1% 0 -.5% 0;
}

.post_details {
  font-size: .73em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  line-height: 5em;
  margin: -1.5% 0 -1.5% 0;
}

.post_featured_author {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.post_featured_author_image_wrapper {
    border: 3px solid #1ddd97cd;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 40px;
    height: 40px;
}

.post_featured_author_image {
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.post_featured_author p {
  align-items: center;
  margin-left: 12px;
}

.disclosure {
  font-size: .7em;
  font-style: italic;
}

.post_divider {
  color: rgb(120, 5, 196);
  font-style: bold;
  font-size: 1.5em;
  word-spacing: 2.5em;
}

.blog_wrapper {
  margin-top: 40px;
}

.blog_short_description {
  font-weight: lighter;
  margin-top: 15px;
}

.blog_content {
  text-align: left;
  font-size: 20px;
  line-height: 1.6;
  margin: -10% 2% 0 2%;
  background-color: rgb(40, 39, 39);
  padding: 1%;
  border-radius: 8px;
  /* box-shadow: 2px 2px 9px; */
}

.blog_content a {
  color: blue;
  text-decoration: underline;
}

.floating-image {
  float: left; 
  max-width: 55%;
  height: auto;
  margin: 10px 26px 16px 0;
}
.floating-image.left {
  float: left;
  margin-right: 16px;
}

.floating-image.right {
  float: right;
  margin-left: 16px;
}

.post_related_section {
  justify-content: center;
  margin: 0 3%;
}

.post_latest_section_title {
  display: flex;
  justify-content: center;
  margin-bottom: -3%;
}

.latest_card_link {
  text-decoration: none;
  outline: none;
}

.latest_card_link:focus {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
}


@media (max-width: 1200px) {

  .floating-image {
    margin: 10px 0;
  }
}

@media (max-width: 850px) {
  .blog_post {
    display: flex;
    flex-direction: column;
  }

  .blog_content {
    font-size: 18px;
    margin: -10% 5% 0 5%;
    padding: 0;
  }

}
