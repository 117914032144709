.about-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 1.1em;
    text-shadow: 3px -4px 10px #000000;
    margin: 3% 20%;
    padding: 4% 4% 0 4%;
    background-color: rgba(255, 255, 255, 0.178);
    color: white;
}

.privacy-policy, .disclaimer, .terms .contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* text-align: center; */
    font-size: 1.1em;
    text-shadow: 3px -4px 10px #000000;
    margin: 3% 20%;
    padding: 4% 4% 0 4%;
    background-color: rgba(255, 255, 255, 0.178);
    color: white;
}

.privacy-policy h1, .disclaimer h1, .terms h1, .about-title, .contact h1 {
    text-align: center;
    font-family: "mr-darcy";
    color: rgb(12, 203, 12);
    margin-bottom: -.01%;
}

.about-author {
    text-align: left;
    margin-top: -5%;
}

.about-page h4 {
    color: rgb(221, 0, 255);
}

.about-text {
    margin-top: 1%;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 77.8vh;
    /* background: #f3f3f3; */
    padding: 5%;
}

.contact h1 {
    margin-top: -80px;
    margin-bottom: 30px;
}

.contact form {
    display: flex;
    flex-direction: column;
    width: 45%;
    border: 1px solid #ddd;
    padding: 2%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
}

.contact form input, 
.contact form textarea {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
}

.contact form textarea {
    resize: none;
    height: 100px;
}

.contact form button {
    background: #9900ff;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.contact form button:hover {
    background: #0056b3;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1.5em;
    z-index: 1000;
}


@media (max-width: 850px) {
    .privacy-policy, .disclaimer, .terms, .about-page {
        margin: 3% 5%;
    }
    .about-page {
        padding: 4% 0 0 0;
    }
    .about-text {
        padding: 4% 4% 0 4%;
    }
    .about-author h1 {
        text-align: left;
    }

    .contact form {
        display: flex;
        flex-direction: column;
        width: 90%;
        border: 1px solid #ddd;
        padding: 2%;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
    }
}