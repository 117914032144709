.category-page {

}

.category_section_title {
    margin: 8% 0 0 3%;
    font-size: 32px;
    letter-spacing: 1.28px;
    color: rgb(246, 240, 235);
    text-shadow: 1px 1px 14px #000000;
}