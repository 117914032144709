* {
    box-sizing: border-box;
}


body {
    background-color: rgb(40, 39, 39);
    color: white;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "amster", serif;
    font-weight: 400;
    font-style: normal; 
    margin: 0;
    overflow-x: hidden;
    word-wrap: break-word;
}

header {
    font-family: "mr-darcy";
    color: white;
}

a {
    /* background-color: transparent; */
    color: var(--color-primary);
    text-decoration: none;
    /* font-weight: 500; */
    /* text-decoration: underline; */
    text-decoration-thickness: 1.5px;
    text-underline-offset: 2px;
}

a:active,
a:hover {
    outline-width: 0;
    /* text-decoration: underline; */
}

h1 {
    margin: 0;
    margin-bottom: 3rem;
    padding: 0;
    line-height: var(--line-height-dense);
    letter-spacing: -0.01em;
    font-family: "mr-darcy";
}

img {
    border-style: none;
    max-width: 100%;
}

.logo {
    max-width: 70px;
    height: auto;
    margin: 2% 1% 0 3%;
}

.logo-container .logo {
    max-width: 70px;
    height: auto;
    /* margin: 2% 1% 0 30%; */
}

.navbar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}  

.navbar-toggler {
    display: flex;
    justify-content: space-between;
}

.d-flex.flex-grow-1.align-items-center {
    justify-content: space-evenly;
    margin-left: 1%;
}
#responsive-navbar-nav {
    margin-left: 2%;
}

.navbar {
    font-family: "mr-darcy";
    width: 100%;
}
  
.banner {
    font-family: "mr-darcy";
    background-image: url(../Assets/banner.png);
    background-size:cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.banner-overlay {
    background-color: rgba(245, 245, 245, 0.457);
    background-size: contain;
}

.banner-text {
    box-shadow: inset 1px 10px 40px 10px rgba(0, 0, 0, 0.916);
    color: rgba(70, 14, 81, 0.937);
    text-shadow: 1px 1px 14px #000000;
}

.banner-text h1 {
    letter-spacing: 3px;
    line-height: 84%;
    font-weight: 400;
    font-style: normal;
}

.banner-text h4 {
    font-family: "amster", serif;
    font-weight: 600;
    font-style: normal;
    margin-top: -30px;
}

  
.regions {
    margin: -60px 0 0 5%;
}
  
.regions-cards {
    display: flex;
    flex-direction: row;
    width: 100%;
}
  
.west, .new-england, .southeast, .midwest {
    width: 78%;
    border-top: 10px double rgb(43, 7, 69);
    border-radius: 10px 10px 0 0;
}

.landing-img {
    width: 100%;
    background-color: #00000000;
    max-height: 30px;
    margin-top: 70px;
    box-shadow: 5px 1px 10px black;
}
  
.welcome {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: .5% 0;
    background: radial-gradient(circle at center, rgba(116, 142, 146, 0.604) 20%, rgba(100, 134, 147, 0), rgba(0, 128, 0, 0) 100%);
}
  
.welcome-text {
    align-self: center;
    text-align: left;
    padding: 13% 10%;
    margin-right: -110px;
    width: 100%;
    text-shadow: 3px -4px 10px #000000;
    z-index: 10;
}

.welcome-blurb {
    font-size: 1.7em;
}

.welcome-text h1 {
    font-family: "mr-darcy";
    font-size: 3em;
    color: rgb(12, 203, 12);
    margin-bottom: -.1px;
}

.welcome-text h4 {
    font-family: "mr-darcy";
    font-size: 2.2em;
    color: rgb(193, 7, 230);
}
  
.welcome-img {
    width: 70%;
    max-width: 700px;
    height: auto;
    margin-left: -10px;
    z-index: 1;
    border: black;
    border-style: double;
    border-width: 8px;
    float: right;
    margin: 5px 0 5px 10px;
}

.floating-image.left {
    float: left;
    margin: 5px 10px 5px 0; 
}
  
.floating-image.right {
    float: right;
    margin: 5px 0 5px 10px;
}

.landing-img2 {
    margin-top: -7%;
}
  
.latest-title h1 {
    margin-top: 5%;
    margin-bottom: -1%;
    justify-content: center;
    text-align: center;
    font-size: 4em;
    text-shadow: 1px 1px 14px #000000;
}
  
/* .full-width {
    width: 100%;
    font-family: "mr-darcy";
    color: white;
} */
  
.mr-auto {
    justify-content:space-evenly;
    align-content: center;
    width: 80%;
    font-family: "mr-darcy";
    color: white;
}
  
.mr-darcy {
    font-family: "mr-darcy";
    color: white;
}

.layout-container {
    /* max-width: 1200px; */
    width: 100%;
    margin: 0 auto;
    /* padding: 0 1em; */
}

.layout-header {
    background: #000;
    color: #fff;
    padding: 1em 0;
    width: 100%;
}

.layout-footer {
    font-size: .9em;
    background: #000;
    color: #fff;
    padding: 1em 0;
    text-align: center;
}


@media (max-width: 850px) {

    .banner-overlay {
        width: 100%;
    }

    .welcome-text h1 {
        font-size: 1.7em;
        margin-top: -3%;
    }

    .welcome-text h4 {
        font-size: 1.1em;
    }

    .welcome-blurb {
        font-size: 1em;
    }

    .latest-title h1 {
        font-size: 3em;
    }
}