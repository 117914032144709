.blog {
    display: flex;
    flex-direction: column;
}

.featured {
    margin: 0 2% 10px 2%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.featured_preview {
    display: flex;
    background-color: rgb(39, 40, 42);
    border-radius: 38px;
    box-shadow: 1px 1px 10px;
    height: 495px;
    overflow: hidden;
}

.featured_section_title {
    margin: 0 0 0px 10px;
    color: rgb(246, 240, 235);
    text-shadow: 1px 1px 1px;
    letter-spacing: 2px;
}

a {
    text-decoration: none;
    color: inherit;
}

.blog_preview {
    border: 1px solid #e0e0e0;
    border-radius: 38px;
    overflow: hidden;
}

.featured_image {
    min-width: 45vw;
    max-width: 45vw;
    height: auto;
    object-fit: cover;
}

.featured_content {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 2%;
    align-items: left;
    justify-content: center;
    text-align: left;
    font-size: 18px;
    line-height: 1.6;
}

.featured_author {
    display: flex;
    flex-direction: row;
    align-content: left;
    margin-bottom: 10px;
}

.featured_author_image {
    width: 3.5vw;
    height: auto;
}

.featured_author p {
    /* font-size: 16px; */
    align-items: center;
    margin-left: 12px;
}

.featured_title {
    color: rgb(119, 5, 213);
    text-shadow: .6px .9px 1px rgba(246, 240, 235, 0.748);
    font-size: 2.1em;
    line-height: 1.2;
}

.featured_subtitle p {
    margin-top: 5px;
    font-size: 1.1em;
}

.featured_date p {
    font-size: .8em;
    margin-top: 5px;
}


.featured_preview a {
    color: inherit;
}

.search-bar {
    display: flex;
    justify-content: center;
    margin: 5% 0;
  }
  
  .search-input {
    width: 60%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-results {
    color: black;
    display:flex;
    position: absolute;
    z-index: 1;
    background: white;
    width: 60%;
    margin: 50px 20%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    text-align: start;
  }
  
  .search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .search-results li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .search-results li:last-child {
    border-bottom: none;
  }
  
  .search-results li:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  

.latest {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 2%;
    margin-bottom: 5%;
}

.latest_cards_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.latest_card_link {
    text-decoration: none;
    color: inherit;
}

.latest_card {
    border: 1px solid #e0e0e0;
    border-radius: 28px;
    overflow: scroll;
    background-color: rgb(39, 40, 42);
    box-shadow: 1px 1px 10px;
    display: flex;
    flex-direction: column;
    border-radius: 28px;
    width: 95%;
    height: 400px;
    margin-bottom: 3%;
}

.latest_card_image {
    object-fit: cover;
    width: 100%;
    height: 60%;
}

.latest_card_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 15px 35px 15px 35px;
    height: 100%;
}

.latest_card_title {
    color: rgb(119, 5, 213);
    text-shadow: .5px .6px 1px rgba(246, 240, 235, 0.752);
    font-weight: 500;
    font-size: 1.2em;
    font-kerning: auto;
}

.latest_author {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.latest_author_image {
    width: 3.5vw;
    height: auto;
}

.latest_author p {
    font-size: 14px;
    line-height: 2.5em;
    text-align: center;
    align-items: center;
    margin-left: 5px;
}

.latest_date {
    margin-left: 5vw;
    align-self: center;
}

.featured_author_image_wrapper,
.latest_author_image_wrapper {
    border: 3px solid #1ddd97cd;
    border-radius: 50%;
    /* display: inline-flex; */
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 40px;
    height: 40px;
}

.featured_author_image,
.latest_author_image {
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.category_section_title {
    margin: 250px 0 0px 10px;
    font-size: 32px;
    letter-spacing: 1.28px;
    color: rgb(246, 240, 235);
    text-shadow: .9px .9px 1px;
}

@media (max-width: 1200px) {
    .latest_cards_container {
        grid-template-columns: repeat(2, 1fr);
    }

    .latest_card {
        height: 390px;
        margin-bottom: 2%;
        margin-left: 2%;
    }

    .latest_card_title {
        font-size: 1.4em;
    }

    .latest_card_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2% 35px 0 35px;
        height: 100%;
    }

    .latest_card_image {
        object-fit: cover;
        width: 100%;
        height: 250px;
    }

    .featured_title {
        font-size: 1.5em;

    }

    .latest_author {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 850px) {
    .blog {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 130px;
    }

    .featured {
        margin: 0 0 0 0;
        text-align: left;
        display: flex;
        /* flex-direction: column; */
        /* gap: 16px; */
    }

    .featured_preview {
        display: flex;
        flex-direction: column;
        border-radius: 0;
        box-shadow: 1px 1px 10px;
        height: auto;
        /* width: 100%; */
        overflow: hidden;
    }

    .featured_image {
        max-width: 100%;
        height: 50vh;
        object-fit: cover;
    }

    .featured_title {
        font-size: 1.3em;
        font-weight: bold;
        margin-top: 1%;
        margin-bottom: -.1%;
    }

    .featured_subtitle {
        font-size: .9em;
        margin-top: 0;
        /* margin-bottom: 20px; */
    }

    .featured_date p {
        font-size: .8em;
        margin: 0 0 20px 0;
    }

    .latest-title {
        margin-bottom: -30%;
    }

    .latest_cards_container {
        grid-template-columns: 1fr;
        height: auto;
    }

}